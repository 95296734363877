import { Screens } from "./screens";

export const DeeplinkConfigs = (): any => {
  return {
    prefixes: ["epointage://", "https://epointage.com"],
    config: {
      screens: {
        Root: {
          initialRouteName: Screens.HOME_SCREEN,
          screens: {
            [Screens.HOME_SCREEN]: "homepage",
          },
        },
        RootAdmin: {
          path: "admin",
          screens: {
            [Screens.HOME_SCREEN_ADMIN]: "admin",
          },
        },
        Callback: {
          path: "callback",
          screens: {
            [Screens.CALLBACK]: "callback",
          },
        }
      },
    },
  };
};
