import React, { useCallback } from "react";
import { View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { NavigationProp } from "@react-navigation/native";
import { AuthActions, AuthThunks } from "@modules";
import { GlobalState, store } from "@redux";
import { Title18 } from "@stylesheets";
import { Auth0DecodedHash, Auth0Error } from "auth0-js";
import { getExpirationTokenInfoFromExpireIn } from "@utils";

interface IProps {
  navigation: NavigationProp<any>;
}

const IS_POINTAGE = true;

export const Auth0CallBack: React.FC<IProps> = ({ navigation }) => {
  const dispatch = useDispatch();
  const isPointageAdmin = useSelector(
    (state: GlobalState) => state?.auth?.isPointageAdmin
  );

  store.dispatch(
    AuthThunks.initializeAuth0Client(IS_POINTAGE, isPointageAdmin)
  );

  const onSuccessCallback = useCallback(
    async (authToken: Auth0DecodedHash) => {
      const expirationTokenInfo = getExpirationTokenInfoFromExpireIn(
        authToken.expiresIn
      );
      dispatch(
        AuthActions.setAuthToken({
          accessToken: authToken.accessToken ?? "",
          refreshToken: authToken.refreshToken ?? "",
          expiresIn: Number(authToken.expiresIn),
          ...expirationTokenInfo,
        })
      );

      dispatch(AuthActions.setLoginStatus(true));
      dispatch(AuthActions.setHavePointageAccount(true));
      if (isPointageAdmin) {
        navigation?.navigate("RootAdmin");
      } else {
        navigation?.navigate("Root");
      }
    },
    [isPointageAdmin]
  );

  const onErrorCallback = useCallback((authError: Auth0Error) => {
    const { error, errorDescription } = authError;
  }, []);

  React.useEffect(() => {
    dispatch(
      AuthThunks.retrieveTokens({
        authResultCallBack: onSuccessCallback,
        authErrorCallback: onErrorCallback,
      })
    );
  }, [navigation, dispatch]);

  return (
    <View>
      <Title18 textAlign="center">Loading permissions</Title18>
    </View>
  );
};
