import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavigationProp } from "@react-navigation/native";
import { AuthActions, AuthThunks } from "@modules";
import { GlobalState, store } from "@redux";
import { Homepage } from "components/src/modules/epointage/screens/Homepage";

interface IProps {
  navigation: NavigationProp<any>;
}

const IS_POINTAGE = true;

export const HomepagePointage: React.FC<IProps> = ({ navigation }) => {
  store.dispatch(AuthThunks.initializeAuth0Client(IS_POINTAGE));
  const dispatch = useDispatch();
  const isLogin = useSelector((state: GlobalState) => state?.auth?.isLogin);
  const isPointageAdmin = useSelector(
    (state: GlobalState) => state?.auth?.isPointageAdmin
  );

  const handleLogin = () => {
    dispatch(AuthActions.setIsPointageAdmin(false));
    dispatch(AuthThunks.login(IS_POINTAGE));
  };

  React.useEffect(() => {
    if (isPointageAdmin && isLogin) {
      navigation?.navigate("RootAdmin");
    }
    if (!isLogin) handleLogin();
  }, [navigation, dispatch, isLogin, isPointageAdmin]);

  return <>{isLogin && <Homepage navigation={navigation} />}</>;
};
